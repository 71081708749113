@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300&display=swap');
.content{
	font-family: 'Poppins', sans-serif;
}
.hover03 .img {
	-webkit-transform: scale(1);
	transform: scale(1);
    border:2px solid grey;
    padding: 3px;
	-webkit-transition: .1s ease-in-out;
	transition: .1s ease-in-out;
}
.hover03 img:hover  {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
    cursor: pointer;
}
.hover03 img:active  {
	-webkit-transform: scale(1);
	transform: scale(1);
    
}
/* .css-yk16xz-control{
	position: fixed !important;
    z-index: 100;
    width: 30%;
    right: 50px;
} */
.AnyClassForContainer {
	position: fixed;
	right: -100px;
	bottom: 150px;
	transition: right 0.5s;
	cursor: pointer;
	background-color: white;
	font-size: 20px;
	padding: 10px;
  }
  
  .AnyClassForTransition {
	right: 20px;
  }